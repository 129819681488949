.main-logo {
    /*z-index: 10000000;
    position: absolute;
    top: 2px;
    left: 2px;*/

    padding: 4px;
    text-align: right;

    img {
        width: 50px;
    }
}

.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    /* border-radius: 50%;*/
    margin-right: 10px;

    font-size: 0.55em;

    position: relative;


    &.add-icon {
        position: absolute;
        right: -1px;
        bottom: -2px;
        width: 12px;
        height: 12px;
    }

    svg {
        width: 20px;
        height: 20px;
    }

    &.icon-dikm {
        background-color: #1781e7;
        border-radius: 2px;
        width: 20px;
        color: white;
    }

    &.icon-donaukm {
        background-color: black;
        border-radius: 2px;
        width: 20px;
        color: white;
    }

    &.icon-dkkm {
        background-color: rgb(89, 89, 89);
        border-radius: 2px;
        width: 20px;
        color: white;
    }

    &.icon-bus {
        background-color: $color-bus;
        color: white;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &.icon-nbus {
        background-color: #0a295d;
        color: #ffed00;

        svg {
            width: 15px;
            height: 15px;
        }
    }



    &.icon-rbus {
        background-color: white;
        color: black;
        border: 1px solid black;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &.icon-tram {
        background-color: $color-tram;
        color: white;

        svg {
            width: 15px;
            height: 15px;
        }
    }

    &.icon-subway {
        border-radius: 0px;

        &.icon-subway-u1 {
            background-color: $color-subway-u1;
            color: white;
        }

        &.icon-subway-u2 {
            background-color: $color-subway-u2;
            color: white;
        }

        &.icon-subway-u3 {
            background-color: $color-subway-u3;
            color: white;
        }

        &.icon-subway-u4 {
            background-color: $color-subway-u4;
            color: white;
        }

        &.icon-subway-u5 {
            background-color: $color-subway-u5;
            color: white;
        }

        &.icon-subway-u6 {
            background-color: $color-subway-u6;
            color: white;
        }
    }
}


.stop {
    width: auto !important;
    white-space: nowrap;


    position: relative;
    padding-left: 10px;

    .stop-dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
        margin-right: 5px;
        border: 2px solid #6d6e72;

        position: absolute;
        left: -6px;
        top: -6px;


    }

    .stop-line {
        background-color: #6d6e72;
        height: 2px;
        position: absolute;
        top: -1px;
        left: 5px;

    }

    .icon {
        /*border-radius: 50%;*/
        justify-content: center;
        align-items: center;
        width: 23px !important;
        height: 23px !important;
        margin-right: 0px !important;
        font-size: 0.85em !important;
        display: inline-flex;
        top: -11px;
        font-weight: bold;

    }
}

#drawer {

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 500px;
    height: auto;
    z-index: 10000000;

    &.open {

        bottom: unset;
        top: 0;
        border-radius: 0;

        .main-logo {
            display: none;
        }


        height: 100vh;
        max-height: 100vh;

        .search {
            background-color: #fff !important;
            border: 2px solid #eee;
        }

        .results {
            height: calc(100vh - 55px);

            overflow-y: auto;
        }

    }

    &.halfopen {

        .results {
            max-height: 30vh;
            overflow-y: auto;
        }

        .search {
            background-color: #fff;
            border: 2px solid #eee;
        }

    }

}

#drawer .wrapper {
    

    background-color: #fffffff0;
    color: #fff;
    
    overflow-y: hidden;
    box-shadow: 0 0 5px #00000047;
    border-radius: 6px 6px 0 0;
    padding: 4px;
    max-width: 500px;

    



    .handle {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 4px;
        background-color: #aaaaaaaa;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 4px;
        display: none;
    }

   

    .search {
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 1px;
        border-radius: 4px;
        background-color: #eeeeee;
        border: 2px solid #eee;
        display: flex;
        align-items: center;
        position: relative;

        svg {
            margin: 0 0px 0 5px;
            color: #aaa;
            height: 20px;
            width: 20px;
        }

        input {
            border: none;
            outline: none;
            background-color: transparent;
            flex: 1;
            padding: 5px;
            color: #333;

            &::placeholder {
                color: #aaa;
            }

            font-size: 0.85em;
        }

        button {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 5px;
            cursor: pointer;
        }
    }

    .infos {
        border-bottom: 0;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 6px;
        line-height: 1.1;

        .info {

            margin-bottom: 5px;
            color: black;

            &.info-xings {
                color: #0049ca;

                small {
                    font-size: 0.85em;
                    position: relative;
                    top: -2px;
                }
            }

            &.info-alt {
                color: #e76f17;
            }

            &.info-ident {
                color: rgb(104, 104, 104);
            }

            &.info-object {
                margin-bottom: 10px;
            }

            .legend {
                font-size: 0.65em;
                opacity: 0.7;
            }

            .text {
                font-size: 0.9em;



                .pill {
                    color: #2a2a2a;
                    background-color: #e4e4e4;
                    border-radius: 5px;
                    margin-right: 5px;
                    padding: 4px 7px;
                    display: inline-block;
                    font-size: 0.75em;

                    &.pill-outlined {
                        background-color: transparent;
                        border: 1px solid #2a2a2a;
                        color: #2a2a2a;
                        padding: 2px 7px 2px 7px;
                    }

                    img.logo {
                        height: 12px;
                        margin-right: 5px;
                    }

                    svg {
                        width: 16px;
                        height: 14px;
                        position: relative;
                        top: -1px;
                        left: -3px;
                    }

                }
            }
        }

    }

    #gsview {
        display: block;
        margin-bottom: 5px;
        display: none;

        img {
            width: 100%;
            border-radius: 5px 5px 0 0;
        }
    }

    .header {
        margin-top: 2px;
        margin-bottom: 2px;
        padding: 6px;
        border-bottom: 0px;
        display: flex;
        align-items: center;

        svg {
            color: #aaa;
            height: 25px;
            width: 25px;
        }



        .h {
            border: none;
            outline: none;
            background-color: transparent;
            flex: 1;

            display: flex;
            align-items: center;

            &::placeholder {
                color: #aaa;
            }

            font-size: 0.9em;
            color: #222;
            font-weight: bold;
        }

        .action {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f6f6f6;
            cursor: pointer;
            margin-left: 5px;
        }



        button {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 5px;
            cursor: pointer;
        }
    }

    .results {



        .result {
            padding: 5px;
            border-top: 1px solid #eee;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            color: #000;

            .text {
                display: flex;
                align-items: center;

                img.arrow {
                    width: 14px;
                    height: 15px;
                    margin-right: 4px;
                    border-radius: 0;
                }
            }

            .bez {
                font-weight: 200;
            }



            .hilite {
                background-color: #c1daffa8;
            }

            &:hover {}

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .info {
                flex: 1;
                padding: 0 10px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                font-size: 0.65em;
                color: #666;




                .email {
                    font-size: 12px;
                    color: #aaa;
                }
            }

            .action {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #f6f6f6;
                cursor: pointer;

                svg {
                    color: #aaa;
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}
$color-bus: #c00808;
$color-tram: #c00808;
/*#d3312c;*/


$color-subway-u1: #e20210;
$color-subway-u2: #935e98;
$color-subway-u3: #db7609;
$color-subway-u4: #319f49;
$color-subway-u5: #049296;
$color-subway-u6: #a4642c;

@import 'drawer.scss';
@import 'lyers.scss';

.hide {
    display: none !important;
}

.hide-menus {

    .tap-locate,
    #lyers,
    .leaflet-control-attribution,
    #drawer {
        display: none !important;
    }
}


html,
body {

    position: fixed;
    font-family: Inter;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

#map {
    width: 100vw;

    height: 100vh;

    top: 0px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}


.km-selected {
    .km:not(.active) {
        opacity: 0.55;
    }
}

.km {
    padding: 3px;
    color: #1781e7;
    font-weight: bold;
    font-size: 20px;
    /*text-shadow: 1px 1px 2px #0000001f;*/

    &.active {
        color: red;

        .dot {
            background-color: red !important;
        }
    }

    &.km-donau {
        color: black;
    }

    &.km-donau.active {
        color: red;
    }

    &.km-dk {
        color: black;
    }

    &.km-dk.active {
        color: red;
    }

}

.baselayer1 {
    .km-donau {
        color: white;
    }

    .km-dk {
        color: white;
    }

    .km-dils {
        color: #5dafff;
    }

    .dot-donau {
        background-color: white !important;
    }

    .dot-dk {
        background-color: white !important;
    }

    .dot-dils {
        background-color: #5dafff !important;
    }
}

.dot {
    background-color: #1781e7;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: 0px;
    left: -8px;

    &.dot-donau {
        background-color: black;
    }

    &.dot-dk {
        background-color: black;
    }
}

.skm {
    padding: 3px;
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    text-shadow: 1px 1px 2px #0000001f
}

.z1 .km-icon:not(.km-major),
.z2 .km-icon:not(.km-major),
.z3 .km-icon:not(.km-major),
.z4 .km-icon:not(.km-major),
.z5 .km-icon:not(.km-major),
.z6 .km-icon:not(.km-major),
.z7 .km-icon:not(.km-major),
.z8 .km-icon:not(.km-major),
.z9 .km-icon:not(.km-major),
.z10 .km-icon:not(.km-major),
.z11 .km-icon:not(.km-major),
.z12 .km-icon:not(.km-major),
.z13 .km-icon:not(.km-major),
.z14 .km-icon:not(.km-major),
.z15 .km-icon:not(.km-major),
.z16 .km-icon:not(.km-major):not(.km-middle) {
    display: none;
}



.z1 .km-icon *,
.z2 .km-icon *,
.z3 .km-icon *,
.z4 .km-icon *,
.z5 .km-icon *,
.z6 .km-icon *,
.z7 .km-icon *,
.z8 .km-icon *,
.z9 .km-icon *,
.z10 .km-icon *,
.z11 .km-icon *,
.z12 .km-icon *,
.z13 .km-icon *,
.z14 .km-icon *,
.z15 .km-icon *,
.z16 .km-icon *,
.z17 .km-icon * {
    font-size: 15px;
}

.z1 .dot,
.z2 .dot,
.z3 .dot,
.z4 .dot,
.z5 .dot,
.z6 .dot,
.z7 .dot,
.z8 .dot,
.z9 .dot,
.z10 .dot,
.z11 .dot,
.z12 .dot,
.z13 .dot,
.z14 .dot,
.z15 .dot,
.z16 .dot,
.z17 .dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: relative;
    top: -1px;
}


.active .km {

    color: green !important;
}

/*
.search {
    mask: linear-gradient(#fff, #fff) top, linear-gradient(#fff, #fff) bottom, linear-gradient(#fff, #fff) left, linear-gradient(#fff, #fff) right;
    -webkit-mask-size: 100% 2px, 100% 2px, 2px 100%, 2px 100%;
    mask-size: 100% 2px, 100% 2px, 2px 100%, 2px 100%;
}
*/

.search.load {
    // background: linear-gradient(#eee, #eee) padding-box, linear-gradient(var(--angle), #eee, #008eef) border-box;
    // background: linear-gradient(#06021d,#06021d)padding-box,linear-gradient(var(--angle),#070707,#687aff)border-box;
    background: linear-gradient(#fff, #fff)padding-box, linear-gradient(90deg, #eee 33%, #008eeffc 50%, #eee 66%) 0 0 / 300% 100%;
    //background: transparent linear-gradient(90deg, transparent 33%, #008eeffc 50%, transparent 66%) 0 0 / 300% 100%;
    //border-radius: 4px;
    -webkit-animation: l1 1s linear infinite;
    animation: 1s l1 linear infinite;
    border: 2px solid #0000 !important;
}

@keyframes rotate2 {
    100% {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 0%;
    }

    25% {
        background-position: 100% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    75% {
        background-position: 0% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}


.loader {
    width: calc(100% - 4px);
    height: 2px;
    background: transparent linear-gradient(90deg, transparent 33%, #008eeffc 50%, transparent 66%) 0 0 / 300% 100%;
    background-size: 300% 100%;
    animation: l1 1s infinite linear;
    display: none;
    position: absolute;
    top: -2px;
    left: 2px;
}

@keyframes l1 {
    0% {
        background-position: right
    }
}



#logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100000000;
    width: 80px;

}

.tap-locate {
    transition: all 0.2s ease-in;


    &.active {
        background-color: #0069ffee;
        color: #fff;
    }
}

.tap-locate-follow {

    border-radius: 2px;
    padding: 4px 7px;
    transition: all .2s ease-in;
    box-shadow: 0 0 5px #00000047;
    background-color: white;
    color: #0069ffee;
    display: none;
    font-size: 0.8em;


    &.active {
        background-color: #0069ffee;
        color: white;
    }
}



.icon-bgred {
    color: #fff;
    background-color: #cc0000;
    opacity: 0.7;
    padding: 1px !important;
}



.leaflet-control-attribution {
    /* transform-origin: 100%; */
    opacity: 0.8;
    font-size: 0.6em;
    padding-top: 1px !important;
    /* transform: rotate(270deg) translate(6px, -4px);*/
}



#miniMap {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100000000;
    width: 150px;
    height: 150px;
    border-radius: 6px;
    box-shadow: 0 0 5px #00000047;
    background-color: transparent;
    transition: all 0.2s ease-in;
    display: none;
    border: 1px solid #ffffff36;

    @media (min-width: 500px) {
        display: block;
    }
}



#navi {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90vw;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    box-shadow: 0 0 5px #00000047;
    background-color: #fff;
    transition: all 0.2s ease-in;
    display: none;
    border: 1px solid #ffffff36;
    z-index: 100000;
    padding: 10px;
    display: none;
    max-width: 400px;

    .coords-pair {
        display: flex;
        flex-direction: row;
        gap: 15px;

    }

    .coords {
        padding: 4px;

        .coord-part {
            display: flex;
            align-items: center;
            gap: 10px;
           
           
        }
    }


    .navis {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        .coords {
            padding: 4px;

            .coord-part {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 0.8em;
                color: #222;
                font-weight: bold;
            }
        }

        .row {
            display: flex;
        }

        .h {
            color: #222;
            background-color: #0000;
            border: none;
            outline: none;
            flex: 1;
            align-items: center;
            font-size: .9em;
            font-weight: bold;
            display: flex
        }

        .action {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f6f6f6;
            cursor: pointer;
            margin-left: 5px;

            img {
                width: 32px;
                max-height: 32px;
            }
        }
    }

    .header {

        margin-bottom: 10px;
        padding: 0px;
        border-bottom: 0px;
        display: flex;
        align-items: center;

        svg {
            color: #aaa;
            height: 18px;
            width: 18px;
        }



        .h {
            border: none;
            outline: none;
            background-color: transparent;
            flex: 1;

            display: flex;
            align-items: center;

            &::placeholder {
                color: #aaa;
            }

            font-size: 0.9em;
            color: #222;
            font-weight: bold;
        }

        .action {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #f6f6f6;
            cursor: pointer;
            margin-left: 5px;
        }



        button {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 5px;
            cursor: pointer;
        }
    }

}


.js-maps {
    svg {
        color: #cc0000 !important;
    }
}